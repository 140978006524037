<template>
    <div class="main-container">
      <h5 class="pageTitle">발급기관</h5>
  
      <div class="form-container">
        <div class="form-group">
          <label for="organization">기관명</label>
          <input v-model="form.organization" type="text" class="input-box" placeholder="기관명을 입력하세요" />
        </div>
  
        <div class="form-group">
          <label for="address">주소</label>
          <input v-model="form.address" type="text" class="input-box" placeholder="주소를 입력하세요" />
        </div>
  
        <div class="form-group">
          <label for="officer">담당자</label>
          <input v-model="form.officer" type="text" class="input-box" placeholder="담당자 이름을 입력하세요" />
        </div>
  
        <div class="form-group">
          <label for="phone">연락처</label>
          <input v-model="form.phone" type="text" class="input-box" placeholder="연락처를 입력하세요" />
        </div>
  
        <div class="button-container">
          <button class="button-list" @click="goToList">목록</button>
          <button class="button-register" @click="registerOrganization">등록</button>
        </div>
      </div>
    </div>
  </template>
  
  
  <script>
import axios from "@/axios";
import Swal from "sweetalert2";

export default {
  name: "CertOrganCreate",
  data() {
    return {
      form: {
        organization: "",
        address: "",
        officer: "",
        phone: "",
      },
    };
  },
  methods: {
    async registerOrganization() {
      const { organization, address, officer, phone } = this.form;

      if (!organization || !address || !officer || !phone) {
        Swal.fire("알림", "모든 필드를 입력해주세요.", "warning");
        return;
      }

      try {
        const tokenData = JSON.parse(sessionStorage.getItem("token"));
        const token = tokenData?.access_token || "";

        await axios.post("/master/issuing-organization", this.form, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        Swal.fire("성공", "발급기관이 등록되었습니다.", "success").then(() => {
          this.$router.push({ name: "master-certOrganRead" });
        });
      } catch (error) {
        console.error("발급기관 등록 실패:", error);
        Swal.fire("오류", "발급기관 등록에 실패했습니다.", "error");
      }
    },
    goToList() {
      this.$router.push({ name: "master-certOrganRead" });
    },
  },
};
</script>

  
  <style scoped>
  /* 기존 스타일 유지 */
  .main-container {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .pageTitle {
    font-weight: bold;
    margin-bottom: 40px;
    text-align: left;
    width: 100%;
  }
  
  .form-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;
    max-width: 800px;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
  
  .input-box, .select-box {
    width: 100%;
    padding: 8px;
    margin-right: 40px;
    border: 1px solid rgba(130, 128, 128, 0.26);
    border-radius: 3px;
    max-width: 600px;
  }
    .input-select {
    width: 200px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  .checkbox {
    margin-top: 10px;
  }
  
  .button-container {
    display: flex;
    gap: 15px;
    justify-content: flex-start;
  }
  
  .button-list {
    background-color: #ff9800;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
  }
  
  .button-register {
    background-color: #4caf50;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    
  }
  </style>
  